.App {
  text-align: center;
  background-color: #ffffff;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-logo {
  width: 100%;
  height: 40vmin;
  pointer-events: none;
}

a {
  color: blue;
}

.copyright {
  color: #808080;
  font-size: calc(6.66px + 1.66vmin);
}

.header {
  font-size: calc(15px + 3vmin);
}